<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>
          <v-icon>devices</v-icon>
          Bewerken apparaat
        </h2>
        <a href="/devices">Terug naar alle apparaten</a>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-4 elevation-1" v-if="device">
          <v-form @submit.prevent="submit">
            <v-text-field
                label="Naam"
                name="name"
                type="text"
                v-model="device.name"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                    label="DevId"
                    name="dev_id"
                    type="text"
                    readonly="readonly"
                    disabled="disabled"
                    v-model="device.devId"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="DevEUI"
                    name="dev_eui"
                    type="text"
                    v-model="device.devEui"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Alarm waarde"
                  name="alarmValue"
                  type="number"
                  step="0.01"
                  v-model="device.alarmValue"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Telefoonnummers (gescheiden door comma)"
                  name="alarmPhoneNumbers"
                  type="text"
                  v-model="device.alarmPhoneNumbers"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Fabrikant"
                    name="manufacturer"
                    type="text"
                    v-model="device.manufacturer"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="Model"
                    name="model"
                    type="text"
                    v-model="device.model"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="Serienummer"
                    name="serial"
                    type="text"
                    v-model="device.serial"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Type"
                    name="type"
                    type="text"
                    v-model="device.type"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="Status"
                    name="status"
                    type="text"
                    v-model="device.status"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-btn color="success" @click="submit"> Bijwerken</v-btn>
            <v-btn :to="{name: 'devices'}">Annuleren</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    device: {}
  }),
  props: ['deviceId'],
  methods: {
    submit() {
      this.$store.dispatch('deviceStore/update', this.device)
        .then(() => {
          this.$router.push({name: 'devices'})
        })
        .catch(err => console.error(err));
    }
  },
  created () {
    const device = this.$store.getters['deviceStore/devices'].find(dev => dev.id === parseInt(this.deviceId))
    if (device) {
      this.device = device;
    }
  }
}
</script>
